<template>
  <div>
    <!-- 添加图标 -->
    <div class="addClass" @click="addRoute">
      <van-icon class="centers" size="0.5rem" name="plus" color="#fff" />
    </div>
  </div>
</template>
<script>
  export default {
    emits: ["addRoute"],
    setup(props, context) {
      function addRoute() {
        context.emit("addRoute");
      }
      return {
        addRoute,
      };
    },
  };
</script>
<style scoped>
  .addClass {
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
    background-color: #2979ff;
    position: fixed;
    right: 0.3rem;
    bottom: 0.3rem;
  }
</style>
